iframe {
  width: 100%;
  height: 80vh;
}

.RowOfMaps {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.SwitchableMap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
}

